import { Dictionary, PhoneNumber, SocialLink, Terms_ConditionsLink,  } from "@models/common";

export const estimateLink: Dictionary = {
  services: {
    solar: "https://swyft.swyftenergy.ie/questionnaire/e63588ac-818b-4a9e-7d7e-08d96098105b",
    boiler: "https://swyft.swyftenergy.ie/questionnaire/2fa4aaf3-6c8b-45fd-ca16-08da13b594c7?utm_source=Google&utm_medium=cpc&utm_campaign=VROOM_-Swyft_Energy_Brand-2020&utm_adgroup=Swyft_Energy-_Brand&utm_term=swyft energy&utm_campaignid=13879256520&utm_adgroupid=125263002472&gclid=Cj0KCQjwguGYBhDRARIsAHgRm49-3vlFMkhuRl2cVCA8H9Yi9qjM99o8Sd1iK4H59xQUO_LDa3AYIIwaAkOBEALw_wcB",
    contact_form: "https://form.jotform.com/SwyftEnergy/contact-us",
    commercial_contact_form: "https://form.jotform.com/SwyftEnergy/contact-us-commercial",
    default: "",
  },
};

export const socialLink: SocialLink = {
  facebook: "https://www.facebook.com/swyftenergy/",
  twitter: "https://twitter.com/swyftenergyie",
  linkedIn: "https://www.linkedin.com/company/swyft-energy/mycompany/",
  youtube: "https://www.youtube.com/channel/UCmqS5nkzn6CMeb-iIYPMHdA",
};

export const googleReviewLink =
  "https://www.google.com/search?q=swyft+energy#lrd=0x484491de0e84a343:0x3beb06c85e8b977f,1";

export const trustpilotReviewLink =
  "https://ie.trustpilot.com/evaluate/swyftenergy.ie";

export const terms_ConditionsLink: Terms_ConditionsLink = {
    domestic_agricultural: "/terms-and-conditions-domestic-agricultural-solar",
    commercial: "/terms-and-conditions-commercial-solar",
    boilers: "/terms-and-conditions-boilers",
    servicing: "/terms-and-conditions-servicing",
  };

export const phoneNumber: PhoneNumber = {
agricultural: "tel:+353871142982",
default: "",
}
