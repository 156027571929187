import { socialLink } from "@constants";
import { AppBar, Box, Button, Drawer, Hidden, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined, MenuOutlined } from "@material-ui/icons";
import { Menus } from "@models";
import { colors } from "@theme";
import { useBreakpoints } from "@utils/breakpoints";
import { getEstimateLink } from "@utils/getEstimateLink";
import { handleEstimate } from "@utils/handleEstimate";
import React, { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from "./icon";
import { MenuComponent } from "./MenuComponent";
import { MobileMenuComponent } from "./MobileMenuComponent";

export const MainNavMenu = (): ReactElement => {
  const classes = useStyles();
  const Menu: Menus[] = [
    {
      title: "Our Services",
      path: "/services",
      isLink: false,
      subMenu: [
        {
          title: "Solar PV Domestic",
          path: "/solar-panels-ireland",
        },
        {
          title: "Solar PV Agriculture",
          path: "/agricultural-solar-panels",
        },
        {
          title: "Solar PV Commercial",
          path: "/commercial-solar-panels",
        },
        {
          title: "Solar PV Schools",
          path: "/schools-solar-panels",
        },
        {
          title: "Boilers",
          path: "/boiler-replacement",
        },
      ],
    },
    {
      title: "Grants",
      path: "/grants",
      isLink: false,
      subMenu: [
        {
          title: "Solar Domestic",
          path: "/solar-panel-grant-ireland",
        },
        {
          title: "Solar Commercial",
          path: "/solar-commercial-grants",
        },
        {
          title: "Solar Agriculture",
          path: "/solar-agriculture-grants",
        },
        {
          title: "Boilers",
          path: "/boiler-grants",
        },
      ],
    },
    {
      title: "Company",
      path: "/company",
      isLink: false,
      subMenu: [
        {
          title: "About Us",
          path: "/about-us",
        },
        {
          title: "Our Approach",
          path: "/our-approach",
        },
        {
          title: "Case Studies",
          path: "/case-studies",
        },
        {
          title: "Testimonials",
          path: "/testimonials",
        },
      ],
    },
    {
      title: "Help",
      path: "/help",
      isLink: false,
      subMenu: [
        {
          title: "Finance",
          path: "/finance",
          subMenu: [
            {
              title: "Solar Domestic",
              path: "/solar-domestic-finance",
            },
            {
              title: "Solar Agriculture",
              path: "/solar-agriculture-finance",
            },
            {
              title: "Solar Commercial",
              path: "/solar-commercial-finance",
            },
            {
              title: "Boilers",
              path: "/boiler-finance",
            },
          ],
        },
        {
          title: "Locations",
          path: "/locations",
        },
        {
          title: "FAQ",
          path: "/faq",
        },
        {
          title: "Blog",
          path: "/blog",
        },
      ],
    },
  ];

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { isLessThanSm, isLessThanMd } = useBreakpoints();
  const estimateLink = getEstimateLink();

  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const isBlack =
    !pathname.includes("/boiler-replacement") &&
    !pathname.includes("/solar-panels-") &&
    !pathname.includes("/agricultural-solar-panels") &&
    !pathname.includes("/commercial-solar-panels") &&
    !pathname.includes("/schools-solar-panels") &&
    !(pathname === "/") &&
    !(
      pathname.includes("/case-studies/peter") ||
      pathname.includes("/case-studies/pat") ||
      pathname.includes("/case-studies/james-farmer-solarpv") ||
      pathname.includes("/case-studies/william-farmer-solarpv") ||
      pathname.includes("/case-studies/jerry-farmer-solarpv") ||
      pathname.includes("/case-studies/wall-commercial-solarpv") ||
      pathname.includes("/case-studies/brendan-farmer-solarpv") ||
      pathname.includes("/case-studies/john") ||
      pathname.includes("/case-studies/ryders") ||
      pathname.includes("/case-studies/pj") ||
      // pathname.includes("/case-studies/malcolm") ||
      pathname.includes("/case-studies/emma") ||
      pathname.includes("/case-studies/ayrton-commercial-solarpv") ||
      pathname.includes("/case-studies/jbs-commercial-solarpv")
    );

  const handleSocialClick = (link: string) => {
    if (!link) {
      return;
    }
    window.open(link, "_blank")?.focus();
  };

  if (pathname === "/boiler-replacement-ppc" || pathname === "/agricultural-solar-panels-landing") {
    return (
      <AppBar position="absolute" className="bg-transparent shadow-none bg-opacity-0 top-0">
        <Toolbar id="back-to-top-anchor">
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            mx="auto"
            alignItems="center"
            height={100}
            className="box-container"
          >
            <Box height={36} onClick={() => navigate("/")} className="cursor-pointer">
              <img loading="lazy" alt="" src="/assets/images/Swyft_logo.svg" className="h-full" />
            </Box>

            <Box display="flex" alignItems="center" position="relative">
              {isLessThanSm ? null : (
                <Typography
                  variant="body1"
                  className={isBlack ? "" : "text-white"}
                  color={isBlack ? "secondary" : "initial"}
                  style={{ marginLeft: "auto", marginRight: "2.7rem" }}
                >
                  +353 1504 2111
                </Typography>
              )}
              {!isLessThanSm && (
                <Box height={46}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ minWidth: 120 }}
                    onClick={() => handleEstimate({ navigate, link: estimateLink })}
                  >
                    <Typography variant="body1" className="whiteText">
                      Get Estimate
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar position="absolute" className="bg-transparent shadow-none bg-opacity-0 top-0">
      <Toolbar id="back-to-top-anchor">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          mx="auto"
          alignItems="center"
          height={100}
          className="box-container"
        >
          <Box height={36} onClick={() => navigate("/")} className="cursor-pointer">
            <img
              loading="lazy"
              alt=""
              src={isBlack ? "/assets/images/Swyft_logo_black.svg" : "/assets/images/Swyft_logo.svg"}
              className="h-full"
            />
          </Box>
          <Hidden smDown>
            <Box
              display="flex"
              alignItems="center"
              className={isBlack ? "" : "text-white"}
              color={isBlack ? "secondary" : "initial"}
            >
              {Menu.map((item, index) => (
                <Box key={index} mx={{ md: 0.5, xl: 1 }}>
                  <MenuComponent data={item} isBlack={isBlack} />
                </Box>
              ))}
              <Box py={2} mx={{ md: 2, xl: 2 }}>
                <Typography
                  variant={isLessThanMd ? "overline" : "body1"}
                  className={isBlack ? "" : "text-white"}
                  color={isBlack ? "secondary" : "initial"}
                >
                  +353 1504 2111
                </Typography>
              </Box>
              <Box height={46} ml={{ md: 3 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="h-full"
                  onClick={() => handleEstimate({ navigate, link: estimateLink })}
                >
                  <Typography variant={isLessThanMd ? "overline" : "body1"} className="whiteText">
                    Get Estimate
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Hidden>
          {isLessThanSm && (
            <Box>
              <Box
                width={42}
                height={42}
                className="cursor-pointer"
                onClick={() => setOpenDrawer(true)}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MenuOutlined color="primary" />
              </Box>
              <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                className={classes.customDrawer}
              >
                <Box bgcolor={colors.secondary.main} display="flex" flexDirection="column">
                  <Box display="flex" justifyContent="space-between" px={3} mt={4}>
                    <Box
                      height={36}
                      onClick={() => {
                        navigate("/");
                        setOpenDrawer(false);
                      }}
                      className="cursor-pointer"
                    >
                      <img loading="lazy" src="/assets/images/Swyft_logo.svg" className="h-full" />
                    </Box>
                    <Box
                      width={40}
                      height={40}
                      borderRadius={10}
                      border={`1px solid ${colors.rose}`}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color={colors.secondary.light}
                      onClick={() => setOpenDrawer(false)}
                      className="cursor-pointer"
                    >
                      <CloseOutlined style={{ color: colors.white }} />
                    </Box>
                  </Box>
                  <Box my={4}>
                    {Menu.map((item, index) => (
                      <MobileMenuComponent data={item} key={index} setOpenDrawer={setOpenDrawer} />
                    ))}
                  </Box>
                  <Box mt={4}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleEstimate({ navigate, link: "" });
                          setOpenDrawer(false);
                        }}
                      >
                        Get Estimate
                      </Button>
                      <Box my={3}>
                        <Typography variant="body1" className="whiteText font-semibold">
                          +353 1504 2111
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Box
                          mx={0.5}
                          className="cursor-pointer"
                          onClick={() => handleSocialClick(socialLink.facebook)}
                          color={colors.pink}
                        >
                          <FacebookIcon />
                        </Box>
                        <Box
                          mx={0.5}
                          className="cursor-pointer"
                          onClick={() => handleSocialClick(socialLink.twitter)}
                          color={colors.pink}
                        >
                          <TwitterIcon />
                        </Box>
                        <Box
                          mx={0.5}
                          className="cursor-pointer"
                          onClick={() => handleSocialClick(socialLink.linkedIn)}
                          color={colors.pink}
                        >
                          <LinkedinIcon />
                        </Box>
                        <Box
                          mx={0.5}
                          className="cursor-pointer"
                          onClick={() => handleSocialClick(socialLink.youtube)}
                          color={colors.pink}
                        >
                          <YoutubeIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles({
  customDrawer: {
    "& .MuiDrawer-paper": {
      backgroundColor: colors.secondary.main,
      paddingBottom: 8,
      width: "100vw",
      height: "100vh",
    },
  },
});
